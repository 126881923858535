<template>
  <div :class="{ 'svg-icon': !removeIconClass }" v-html="icon"/>
</template>

<script setup>
import { icons } from '~/assets/data/icons';

const props = defineProps({
  icon: {
    type: String,
    default: null,
  },
  width: {
    type: String,
    default: null,
  },
  height: {
    type: String,
    default: null,
  },
  removeIconClass: {
    type: Boolean,
    default: false,
  },
});

const icon = computed(() => icons[props.icon]);
const width = ref(`${props.width ?? 26}px`);
const height = ref(`${props.height ?? 26}px`);
</script>
<style lang="scss" scoped>
.svg-icon {
  display: inline-flex;
  width: v-bind(width);
  height: v-bind(height);

  > :deep(svg) {
    margin: auto;
    flex: 1 1 auto;
  }
}
</style>
